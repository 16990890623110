import { React } from "react";
import { FirstTitle } from "./FirstTitle";
import { HomepageBackground } from "./HomepageBackground";

export const HomeIndex = () => {
  return (
    <div>
      {/* <FirstTitle /> */}
      <HomepageBackground />
    </div>
  );
};
