export const ProjectsImageThree = ({ pic }) => {
  return (
    <div className="projects-artwork-container">
      <img
        className="projects-artwork-2"
        src={pic}
        alt="pictures of the gosse project"
      ></img>
    </div>
  );
};
