export const ProjectsImageTwo = ({ pic }) => {
  return (
    <div className="projects-artwork-container">
      <img
        className="projects-artwork-twin"
        src={pic}
        alt="pictures of the cotta website"
      ></img>
    </div>
  );
};
